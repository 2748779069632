import { styled } from 'stitches.config'
import Image from 'next/image'

export const Logo = styled(Image, {
  width: '183px',
  height: '42px',
  margin: 'auto',

  '@bp1': {
    width: '296px',
    height: '64px',
  },
})

export const MainNav = styled('nav', {
  margin: 'auto',

  '& ul': {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '$3',
    alignItems: 'center',
  },

  '@bp1': {
    '& ul': {
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      flexWrap: 'wrap',
    },
  },
  '@bp2': {
    '& ul li:last-child': {
      display: 'none',
    },
  },
})

export const SocialMediaNav = styled('nav', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  // '@bp1': {
  //   '& ul li:last-child': {
  //     display: 'none',
  //   },
  // },
})

export const ContactUsNav = styled('nav', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '@bp1': {
    '& ul li:last-child': {
      display: 'none',
    },
  },
})

export const FooterNavigation = styled('div', {
  py: '$5',
  px: '$3',
  width: '100%',

  '@bp1': {
    paddingTop: '$7',
    paddingBottom: '$5',
  },
})

export const PoliciesNav = styled('nav', {
  fontSize: '$small',
  fontWeight: '$regular',
  lineHeight: '$small',
  marginBottom: '$4',
  color: '$accent-black',
  fontFamily: '$body',

  '& ul': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '$5',
    '& li': {
      flex: '0 1 auto',
    },
  },

  '@bp1': {
    '& ul': {
      padding: 0,
    },
  },

  '@bp2': {
    order: 1,
  },
})

export const ContactUs = styled('div', {
  fontSize: '$small',
  display: 'none',
  order: 2,
  '@bp2': {
    display: 'flex',
    alignItems: 'flex-start',
  },
})

export const FooterPolicies = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  flexDirection: 'column',
  paddingTop: '$3',
  paddingBottom: '$14',
  px: '$2',
  width: '100%',
  '& > *': {
    flex: '1 1 0px',
    justifyContent: 'center',
  },

  '@bp2': {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingTop: 0,
    paddingBottom: '$4',
    px: '$5',
  },
})

export const FooterRoot = styled('footer', {
  color: '$accent-black',
  backgroundColor: '$accent-white',
  fontFamily: '$body',
})

export const SocialMediaList = styled('ul', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: '$5',

  '@media screen and (max-width: 440px)': {
    maxWidth: '60%',
  },

  '@bp1': {
    gap: '$6',
    maxWidth: '100%',
  },
})
